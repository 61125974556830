<template>
  <v-row>
<!--    <v-col cols="2" v-for="file in files" :key="file.name">-->
<!--      <v-card>-->
<!--        <v-img-->
<!--            :src="file.image"-->
<!--            height="125"-->
<!--            class="grey darken-4"-->
<!--        ></v-img>-->
<!--        <v-card-title class="text-h6">-->
<!--          {{ file.name }}-->
<!--        </v-card-title>-->
<!--        <v-card-actions>-->
<!--          <a :href="file.fileItem">Download</a>-->
<!--        </v-card-actions>-->
<!--      </v-card>-->
<!--    </v-col>-->
    <v-card
        max-width="600"
        class="mx-auto"
    >
      <v-list
          subheader
          two-line
      >
        <v-subheader inset>Contenus Téléchargeables</v-subheader>

        <v-list-item
            v-for="file in files" :key="file.name"
        >
          <v-list-item-avatar>
            <v-img
                src="https://firebasestorage.googleapis.com/v0/b/frontvue-fiveaday.appspot.com/o/design%2Ficon-sheet.png?alt=media&token=a69d1873-ea7d-4361-89fd-1ae6195965c2"
                height="125"
                contain
            ></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="file.name"></v-list-item-title>

            <v-list-item-subtitle v-text="file.description"></v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <a :href="file.fileItem" target="_blank">
              <v-img
                  src="https://firebasestorage.googleapis.com/v0/b/frontvue-fiveaday.appspot.com/o/design%2FPlan%20de%20travail%2035.svg?alt=media&token=286da32d-9fff-476d-92f5-0a8e044fb805"
                  contain
                  max-height="25"
              />
            </a>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </v-row>
</template>

<script>
import {db} from "../../main";

export default {
  name: "Rh",
  data() {
    return {
      files: []
    }
  },
  methods: {
    async getFiles() {
      await db.collection("files").where("category", "==", "Ressources Humaines").get().then((querySnapshot) => {
        const files = []
        querySnapshot.forEach((doc) => {

          files.push(doc.data());
        })
        this.files = files
      });
      return this.files
    },
  },
  created(){
    this.getFiles();
  }
}
</script>

<style scoped>

</style>